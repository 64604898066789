import React, {Component} from 'react';
import {connect} from 'react-redux';
import {InitTabbedViewData, InitCustomStoreForTabbedView} from './LeopardActionCreators';
import TabPanel from "devextreme-react/tab-panel";
import LDH from "../helpers/LeopardDataHelper";
import LeopardTabbedViewTabItemEngine from "./LeopardTabbedViewTabItemEngine";
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";

class LeopardTabbedViewEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parameters: [],
            tabbedViewDefinition: null
        };
        this.uiObjectInstances = [];
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
    }

    componentDidMount = () => {
        let definition = this.props.definition.tabbedViewDefinition;
        this.setState({tabbedViewDefinition: definition});
    };

    setTabbedViewInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null) return;
        if (!LDH.IsObjectNull(this.uiObjectInstances.tabbedViewInstance)) {
            return;
        }
        this.uiObjectInstances.tabbedViewInstance = ref.instance;
        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstances.tabbedViewInstance.option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback(data) {
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let tabbedViewDefinition = this.props.definition.tabbedViewDefinition;
                            data["tabbedViewDefinition"] = tabbedViewDefinition;

                            if (!LDH.IsObjectNull(tabbedViewDefinition.tabSelectionLogic) &&
                                !LDH.IsValueEmpty(tabbedViewDefinition.tabSelectionLogic)) {
                                let javascript = tabbedViewDefinition.tabSelectionLogic;
                                let dataName = "data";
                                let dataValue = data;
                                let tabId = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, that.props.dataViewId);
                                if (!LDH.IsObjectNull(tabId) && !LDH.IsValueEmpty(tabId)) {
                                    let tabParametersCloned = LDH.DeepClone(tabbedViewDefinition.tabParameters);
                                    tabParametersCloned.sort(function (a, b) {
                                        let orderA = LDH.IsValueEmpty(a.tabPageOrder) ? 1 :
                                            parseInt(a.tabPageOrder);
                                        let orderB = LDH.IsValueEmpty(b.tabPageOrder) ? 1 :
                                            parseInt(b.tabPageOrder);
                                        if (orderA < orderB) return -1;
                                        if (orderA > orderB) return 1;
                                        return 0;
                                    });

                                    let index = 0;
                                    for (let v = 0; v < tabParametersCloned.length; v++) {
                                        if (tabParametersCloned[v].id === tabId) {
                                            data["sendToDataViewId"] = tabParametersCloned[v].dataViewIdForTabPage;
                                            data["sendFromTabId"] = tabId;
                                            index = v;
                                            break;
                                        }
                                    }
                                    that.uiObjectInstances.tabbedViewInstance.option("selectedIndex", index);
                                    let registedListeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;

                                    for (let b = 0; b < tabParametersCloned.length; b++) {
                                        let dvId = tabParametersCloned[b].dataViewIdForTabPage;
                                        for (let c = 0; c < registedListeners.length; c++) {
                                            if (registedListeners[c].dashboardItemId === dvId) {
                                                if (LDH.IsObjectNull(data.dataFromSource)) {
                                                    data.dataFromSource = {};
                                                    data.dataFromSource["CallChildViewOnNonSelection"] = true;
                                                } else {
                                                    data.dataFromSource["CallChildViewOnNonSelection"] = false;
                                                }
                                                registedListeners[c].callback(data);
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }
    };

    initializeTabItemTitle = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        return (
            <LeopardTabbedViewTabItemEngine
                setGridViewInstanceForChildView={this.setGridViewInstanceForChildView}
                updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsRequired(e)}
                tabbedViewDefinition={this.state.tabbedViewDefinition}
                relationships={this.relationships}
                tabId={data.tabId}
                changeTabbedViewTabId={this.changeTabbedViewTabId}
            >
            </LeopardTabbedViewTabItemEngine>
        );
    };

    updateWindowDimensionsRequired = (e) => {
        return;
    };

    setGridViewInstanceForChildView = (instanceData) => {
        return;
    };

    changeTabbedViewTabId = (data) => {
        this.uiObjectInstances.tabbedViewInstance.option("selectedIndex", data.tabIndex);
    }

    render() {
        let that = this;
        let definition = this.state.tabbedViewDefinition;
        let datasource = [{tabId: 0, tabTitle: '', tabPageOrder: 1}];

        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.tabParameters) &&
            definition.tabParameters.length > 0) {
            datasource = [];

            let clonedTabParameters = LDH.DeepClone(definition.tabParameters);
            for (let i = 0; i < clonedTabParameters.length; i++) {
                datasource.push({
                    tabId: clonedTabParameters[i].id,
                    tabTitle: clonedTabParameters[i].tabTitle,
                    tabPageOrder: LDH.IsValueEmpty(clonedTabParameters[i].tabPageOrder)
                        ? 1 : clonedTabParameters[i].tabPageOrder
                });
            }

            datasource.sort(function (a, b) {
                let orderA = LDH.IsValueEmpty(a.tabPageOrder) ? 1 :
                    parseInt(a.tabPageOrder);
                let orderB = LDH.IsValueEmpty(b.tabPageOrder) ? 1 :
                    parseInt(b.tabPageOrder);
                if (orderA < orderB) return -1;
                if (orderA > orderB) return 1;
                return 0;
            });
        }

        let enableTabSelection = true;
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.enableTabSelection)) {
            enableTabSelection = definition.enableTabSelection;
        }

        return (
            <React.Fragment>
                {enableTabSelection ? null :
                    <div className={"leopard_tabbedview_viewer_container_disabled_cover"}></div>}
                <TabPanel dataSource={datasource} deferRendering={false} showNavButtons={true}
                          loop={true} itemTitleRender={this.initializeTabItemTitle}
                          id={"leopard-tabbedview-tabpanel-container-" + this.props.dataViewId}
                          className={"leopard-tabbedview-tabpanel-container" + " " + (enableTabSelection ? "" : "disabled")}
                          animationEnabled={false} ref={(e) => that.setTabbedViewInstance(e)}
                          itemComponent={this.tabItemContent} swipeEnabled={false}
                ></TabPanel>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitTabbedViewData: (data, id) => {
            dispatch(InitTabbedViewData(data, id));
        },
        InitCustomStoreForTabbedView: (store, id) => {
            dispatch(InitCustomStoreForTabbedView(store, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardTabbedViewEngine);
